import { AuthProvider } from "./contexts/AuthContext";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/not_found";
import "./App.css";
import Header from "./components/Header";
import RequireAuth from "./components/RequireAuth";
import ProfilePage from "./pages/profile";
import { ThemeProvider } from "./contexts/ThemeContext";
import AboutPage from "./pages/about";
import SignUpPage from "./pages/sign_up";
import SignInPage from "./pages/sign_in";
import AdminPage from "./pages/admin";
import { Container } from "@mui/material";
import { Footer } from "./components/Footer";
import SignOutPage from "./pages/sign_out";
import React from "react";
import ParserPage from "./pages/parser/parser";
import ParsingResult from "./pages/parser/parsing_result";
import AdsView from "./pages/parser/ads_view";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import BaseResult from "./pages/base/base_result";
import ProtectedRoute from "./tools/routes/ProtectedRoute";
const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AuthProvider>
          <Header></Header>
          <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2 } }}>
            <div className="App">
              <Routes>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <HomePage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/sign-in" element={<SignInPage />} />
                <Route path="/sign-up" element={<SignUpPage />} />
                <Route path="/sign-out" element={<SignOutPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route
                  path="/parser"
                  element={
                    <ProtectedRoute>
                      <ParserPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/parser/total_parsed"
                  element={
                    <ProtectedRoute>
                      <ParsingResult title={"Всі оголошення"} filter={"all"} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/parser/from_owner"
                  element={
                    <ProtectedRoute>
                      <ParsingResult
                        title={"Оголошення від власників"}
                        filter={"owners"}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/base"
                  element={
                    <ProtectedRoute>
                      <BaseResult title={"База"} filter={""} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/apartment/:adsId"
                  element={
                    <ProtectedRoute>
                      <AdsView item={null} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <RequireAuth>
                      <ProfilePage />
                    </RequireAuth>
                  }
                >
                  <Route path="edit" element={<></>} />
                </Route>

                <Route
                  path="/admin"
                  element={
                    <RequireAuth>
                      <AdminPage />
                    </RequireAuth>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
            <Footer></Footer>
          </Container>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
