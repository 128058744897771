import { FetchArgs, retry } from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({ baseUrl: "/api/" })(
      args,
      api,
      extraOptions
    );
    if (result.error?.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }

    return result;
  }
);
