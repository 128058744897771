import { Box, Card, Tab, Tabs, Typography } from "@mui/material";

import UsersCRUD from "../components/admin/Users";
import { useLocalStorage } from "../hooks/useLocalStorage";

function a11yProps(index: number) {
  return {
    id: `admin-tab-${index}`,
    "aria-controls": `admin-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AdminPage = () => {
  const { value, setItem: setValue } = useLocalStorage("adminTab");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };

  const activeIndex = parseInt(value || "0");
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Users" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel value={activeIndex} index={0}>
            <UsersCRUD></UsersCRUD>
          </TabPanel>
        </Box>
      </Card>
    </>
  );
};
export default AdminPage;
