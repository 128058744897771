import { LoginData, RegisterData, UserModel } from "../types";
import api from "./server";

interface AuthResponse {
  access: string;
  refresh: string;
}

export async function register(data: RegisterData): Promise<AuthResponse> {
  const response = await api.post(`/auth/register`, data);
  return response.data;
}

export async function login(data: LoginData): Promise<AuthResponse> {
  const response = await api.post(`/api/v1/login/`, data);
  return response.data;
}

export async function getProfile(token: string): Promise<UserModel> {
  const response = await api.get(`/identity`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
