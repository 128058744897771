import React from "react";
import { Grid } from "@mui/material";
import DashboardItem from "../components/DashboardItem";

const HomePage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "70vh",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <DashboardItem title="Парсер" value="100500" href="/parser" />
        <DashboardItem title="База" value="4000" href="/base" />
        <DashboardItem title="Перегляди" value="300" />
        <DashboardItem title="Активні клієнти" value="1500" />
        <DashboardItem title="Активні чати" value="300" />
      </Grid>
    </div>
  );
};
export default HomePage;
