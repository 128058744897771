import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface AppState {
  isLoading: boolean
  isApiDetected: boolean
}

const initialState = { isLoading: false, isApiDetected: false } as AppState

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setIsApiDetected(state, action: PayloadAction<boolean>) {
      state.isApiDetected = action.payload
    }
  },
})

export const { setIsLoading, setIsApiDetected } = AppSlice.actions
export default AppSlice.reducer
