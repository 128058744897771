import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { useLocalStorage } from "../../hooks/useLocalStorage";

interface AuthState {
  isLoading: boolean;
  token: string;
}

const initialState = { isLoading: false, token: "" } as AuthState;

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
    },
  },
});

export const { setAuth } = AuthSlice.actions;
export default AuthSlice.reducer;
