export const numberWithSpaces = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatDate = (date: Date) => {
  return date.toLocaleDateString("uk-UA", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const formatDatetime = (date: Date) => {
  return date
    .toLocaleTimeString("uk-UA", {
      hour: "numeric",
      minute: "numeric",
    })
    .concat(" ")
    .concat(formatDate(date));
};

export const convertGMTToLocal = (gmtTime: string | Date): string => {
  const dateObj = new Date(gmtTime);
  const localTime = new Date(
    dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
  );
  return localTime.toISOString();
};
