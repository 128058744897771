import { Typography } from "@mui/material";
import React from "react";

const AboutPage = () => {
  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: 1, marginTop: 1 }}>
        Про проект
      </Typography>
    </>
  );
};

export default AboutPage;
