import { Link as RouterLink, Route, Routes } from "react-router-dom";
import { Box, Button, ButtonGroup } from "@mui/material";
import Profile from "../components/Profile";
import ProfileEditPage from "./profile_edit";
import { useUser } from "../hooks/useUser";
import { ReactElement } from "react";

const ProfilePage = () => {
  const user = useUser();

  if (!user) {
    return <></>;
  }

  const links = [["/profile", "Профіль"]];
  const routes: { [key: string]: ReactElement } = {
    "/": <Profile />,
    "/edit": <ProfileEditPage />,
  };

  return (
    <Box sx={{ mt: 1 }}>
      <ButtonGroup>
        {links.map((l) => (
          <Button component={RouterLink} key={l[0]} to={l[0]}>
            {l[1]}
          </Button>
        ))}
      </ButtonGroup>
      <Routes>
        {Object.entries(routes).map(([path, el]) => (
          <Route key={path} path={path} element={el} />
        ))}
      </Routes>
    </Box>
  );
};
export default ProfilePage;
