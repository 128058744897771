import { User } from "../types";
import api from "./server";

export async function updateProfile(user: User): Promise<User> {
  const response = await api.put(`/protected/users/profile`, user);
  return response.data;
}


export async function getUploadUrl(): Promise<{
  uploadURL: string;
  id: string;
}> {
  const response = await api.post(`/upload-url`);
  return response.data;
}
