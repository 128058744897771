import React from "react";
import { Box, Card, Grid, Link, Stack, Typography } from "@mui/material";
import { BorderLinearProgress } from "../components/BorderLinearProgress";

function DashboardItem({
  title,
  value,
  progress,
  href = "",
}: {
  title: string;
  value: string;
  progress?: number;
  href?: string;
}) {
  return (
    <Grid item md={4} xs={12}>
      <Card className="ProductCard">
        <Box sx={progress ? { p: 6 } : { p: 7.25 }}>
          <Stack direction="column" alignContent={"flex-start"}>
            <Typography variant="h3">{value}</Typography>
            {typeof progress !== "undefined" && (
              <BorderLinearProgress
                variant="determinate"
                value={progress}
              ></BorderLinearProgress>
            )}
            <Link href={href}>
              <Typography variant="h5" sx={{ mb: 1 }}>
                {title}
              </Typography>
            </Link>
          </Stack>
        </Box>
      </Card>
    </Grid>
  );
}

export default DashboardItem;
