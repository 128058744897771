import { Repository, User } from "../types";
import api from "./server";

export class UsersRepository implements Repository<User> {
  async fetchAll(): Promise<User[]> {
    const response = await api.get<User[]>("/users");
    return response.data;
  }

  async fetchById(id: string | number): Promise<User | undefined> {
    const response = await api.get<User>(`/users/${id}`);
    return response.data;
  }

  async createOrUpdate(user: User): Promise<User> {
    if ("id" in user && user.id) {
      const response = await api.patch<User>(`/users/${user.id}`, user);
      return response.data;
    } else {
      const response = await api.post<User>(`/users`, user);
      return response.data;
    }
  }

  async deleteById(id: string | number): Promise<void> {
    await api.delete(`/users/${id}`);
  }
}
