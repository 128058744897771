import React from "react";
import { Grid } from "@mui/material";
import DashboardItem from "../../components/DashboardItem";

const ParserPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "70vh",
        alignItems: "center",
      }}
    >
      <Grid container spacing={1} direction="row" sx={{ mt: 1 }}>
        <DashboardItem
          title="Всі Оголошення"
          value="200"
          progress={90}
          href="/parser/total_parsed"
        />
        <DashboardItem
          title="Оголошення від власників"
          value="30"
          href="/parser/from_owner"
        />
        <DashboardItem
          title="Існуючі оголошення"
          value="70"
          href="/parser/already_exist"
        />
        <DashboardItem
          title="У нас в архиві"
          value="20"
          href="/parser/in_archive"
        />
        <DashboardItem
          title="Фото дублікати"
          value="20"
          href="/parser/photo_duplicate"
        />
      </Grid>
    </div>
  );
};

export default ParserPage;
