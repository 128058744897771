import * as React from "react";
import { useState } from "react";
import { login as loginRequest } from "../api";
import { useAuth } from "../hooks/useAuth";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CardActions, CardContent, Link } from "@mui/material";
import BoxCard from "./BoxCard";

const AUTH_BOT = process.env.REACT_APP_AUTH_BOT || "real-estate-admin";  //@TODO: change it
const Login = () => {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await loginRequest(formData);
      login(response.access);
    } catch (error) {
      setErrorMessage("Неправильний логін або пароль");
    }
  };

  const telegramAuthLink = `https://t.me/${AUTH_BOT}?start=auth`;

  return (
    <BoxCard title="Увійти">
      <CardContent>
        {errorMessage && (
          <Typography color="error" sx={{ pb: 3 }}>
            {errorMessage}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label="Логін"
              variant="outlined"
              fullWidth
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Пароль"
              variant="outlined"
              fullWidth
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            <Button variant="contained" type="submit" fullWidth>
              Увійти
            </Button>
          </Stack>
        </form>
      </CardContent>
      <CardActions sx={{ p: 2, pt: 1 }}>
        <Typography variant="body2" color="text.secondary">
          <Link href={telegramAuthLink}>Увійти за допомогою Telegram</Link>
        </Typography>
      </CardActions>
    </BoxCard>
  );
};

export default Login;
