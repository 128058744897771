import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_API_URL } from "../api/server";
import { ParserData } from "../types";

export const dataApi = createApi({
  reducerPath: "dataApi",
  baseQuery: fetchBaseQuery({ baseUrl: DEFAULT_API_URL }),
  endpoints: (builder) => ({
    getParsedData: builder.query<ParserData[], void>({
      query: (credentials) => ({
        url: "/api/v1/ads/",
        method: "GET",
        body: credentials,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),
    sendParsedData: builder.mutation<string, ParserData>({
      query: (data) => ({
        url: "/sendParsedData",
        method: "POST",
        body: data,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),
  }),
});
export const {} = dataApi;
