import React from "react";
import { Box, Link, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Box sx={{ py: "1rem", display: "flex", justifyContent: "space-between" }}>
      <Typography
        style={{ opacity: 0.25 }}
        variant="body2"
        color="textSecondary"
      >
        {"© "}
        {new Date().getFullYear()}
        {" NullReferenceException"}
      </Typography>
    </Box>
  );
};
