import { createApi } from "@reduxjs/toolkit/query/react";
import type { RegisterData } from "../types";
import { staggeredBaseQueryWithBailOut } from "./interceptor";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: (builder) => ({
    auth: builder.mutation<RegisterData, RegisterData>({
      query: (credentials) => ({
        url: `${process.env.REACT_APP_API_SERVER}/login`,
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation<RegisterData, RegisterData>({
      query: (credentials) => ({
        url: "auth/register",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});
export const { useAuthMutation, useRegisterMutation } = authApi;
