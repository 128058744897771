import { useUser } from "../hooks/useUser";
import { Link as RouterLink } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import React from "react";

const Profile = () => {
  const user = useUser();

  return (
    <div>
      <Typography variant="h4" sx={{ marginTop: 1, marginBottom: 1 }}>
        {user?.first_name} {user?.last_name}
      </Typography>
      <p>Email: {user?.email}</p>
      <p>Telegram: {user?.telegram}</p>
      <p>Instagram: {user?.instagram}</p>
      <p>Facebook: {user?.facebook}</p>
      <p>Twitter: {user?.twitter}</p>
      <p>
        Про себе: <br />
        {user?.description}
      </p>
      <Button variant="outlined" component={RouterLink} to="/profile/edit">
        Редагувати дані
      </Button>
    </div>
  );
};

export default Profile;
